<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  metaInfo: {
    titleTemplate: "%s | Thybault Gobert - Développeur JS"
  }
};
</script>
<style>
:root {
  --header-primary: #fff;
  --header-secondary: #8e9297;

  --text-primary: #fdfdfd;
  --text-secondary: #dddddd;
  --text-accent: #f4b942;
  --text-accent-ligth: #92adfd;
  --text-dark: #101024;

  --background-main: #12122a;
  --background-primary: #12122a;
  --background-secondary: #202131;
  --background-blue: #3a5bbd;
  --background-hover: #36393f;

  --background-accent: #feb633;
  --background-floating: #18191c;

  --color-red: #f04747;
  --color-orange: #faa61a;
  --color-green: #43b581;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: var(--background-main);
  font-family: "Open Sans", sans-serif;
  color: var(--text-primary);
}
textarea {
  font-family: inherit;
}
a {
  text-decoration: none;
  color: #fdfdfd;
}

h1 {
  font-weight: 700;
  font-size: 50px;
  text-transform: uppercase;
}

h1.title {
  font-size: 60px;
  margin-top: 140px;
}

.hov:hover h1 {
  transition-delay: 0.1s;
  color: var(--text-dark);
}
h2 {
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
  line-height: 23px;
  color: var(--text-secondary);
}

/* effects text*/
.highlightEffect::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: -0.25em;
  right: -0.25em;
  background-color: var(--background-accent);
  transform-origin: center right;
  transform: scaleX(0);
  transition: transform 0.2s ease-in-out;
}

.section:hover .highlightEffect::before {
  transform: scaleX(1);
  transform-origin: center left;
}

@media only screen and (max-width: 1000px) {
  h1 {
    font-size: 35px;
  }
  h1.title {
    font-size: 40px;
    margin-top: 140px;
  }
}
@media only screen and (max-width: 750px) {
  h1 {
    font-size: 35px;
  }
}
</style>
