import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/apropos/",
    name: "about",
    component: () => import("../views/About.vue")
  },
  {
    path: "/realisations/",
    name: "portfolio",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Portfolio.vue")
  },
  {
    path: "/contact/",
    name: "contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Contact.vue")
  }
  /*  {
    path: "/apropos2/",
    name: "about2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/About2.vue"),
    children: [
      {
        path: "experience",
        component: Experience
      },
      {
        path: "formation",
        component: Education
      },
      {
        path: "competences",
        component: Skill
      },
      {
        path: "",
        component: Experience
      }
    ]
  }*/
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/about.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return !savedPosition ? { x: 0, y: 0 } : savedPosition;
  },
  routes
});

export default router;
