<template>
  <div id="centerContainer">
    <section class="section cover">
      <div class="int">
        <h1 class="upper">Thybault <span class="color">Gobert</span></h1>
        <TypeEffect />
      </div>
    </section>

    <router-link tag="section" :to="{ name: 'about' }" class="section">
      <div class="int hov">
        <h1 class="upper highlightEffect">A PROPOS DE MOI</h1>
      </div>
    </router-link>

    <router-link tag="section" :to="{ name: 'portfolio' }" class="section">
      <div class="int hov">
        <h1 class="upper highlightEffect">Réalisations</h1>
      </div>
    </router-link>

    <router-link tag="section" :to="{ name: 'contact' }" class="section">
      <div class="int hov">
        <h1 class="upper highlightEffect">Contact</h1>
      </div>
    </router-link>
  </div>
</template>

<script>
// @ is an alias to /src
import TypeEffect from "@/components/TypeEffect";
export default {
  name: "home",
  metaInfo: {
    title: "Thybault Gobert - Développeur JS",
    titleTemplate: null
  },
  components: {
    TypeEffect
  }
};
</script>

<style>
#centerContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

#centerContainer .section:not(.cover) .int {
  background: var(--background-secondary);
  cursor: pointer;
}

.section {
  width: 50vw;
  height: 50vh;
  padding: 5px;
}

.section .int {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
}

.color {
  color: var(--text-accent);
}

.highlightEffect {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 750px) {
  .section {
    width: 100vw;
    height: 50vh;
    padding: 5px;
    word-wrap: break-word;
  }
}
</style>
